import React, { useState, useEffect } from 'react';
import { CiCirclePlus } from "react-icons/ci";
import { FaRegTrashAlt} from "react-icons/fa";
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/Button';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks';
import { setDocumentsMetaInfo, setActiveDocumentId } from '../store/documentsSlice';
import { setCurrentCredits, setMaxCredits } from '../store/creditsSlice';

import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    UserCircleIcon,
    LightBulbIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/16/solid'

import { Avatar } from '../components/Avatar'

import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarHeading,
    SidebarItem,
    SidebarSection,
    SidebarSpacer,
} from '../components/Sidebar'

import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../components/Dropdown'

import { FaLightbulb } from "react-icons/fa";

const CreditBar: React.FC = () => {

    const { user } = useAuth();
    const dispatch = useAppDispatch();
    var currentCredits = useAppSelector((state) => state.credits.currentCredits);
    const maxCredits = useAppSelector((state) => state.credits.maxCredits);

    var filledPercentage = (currentCredits / maxCredits) * 100;
    if (filledPercentage > 100) {
        filledPercentage = 100;
    }
    if (filledPercentage < 0) {
        filledPercentage = 0;
    }

    useEffect(() => {
        if (!user) return;
        dispatch(setCurrentCredits(user.currentCredits));
        dispatch(setMaxCredits(user.maxCredits));
    }, [user]);

    return (
        <div className="px-4 py-2">
            <div className="flex justify-between text-sm text-zinc-500">
                <span>AI Credits</span>
                <span>{Math.max(Math.floor(currentCredits), 0)}/{Math.floor(maxCredits)}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
                <div
                    className="bg-gray-600 h-2.5 rounded-full"
                    style={{ width: `${filledPercentage}%` }}
                ></div>
            </div>
        </div>
    );
};

function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const handleLogout = () => {
        localStorage.removeItem('token');
        logout();
    };

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => { navigate('/settings') }}>
                <UserCircleIcon />
                <DropdownLabel>Profile Settings</DropdownLabel>
            </DropdownItem>
            {/* <DropdownDivider />
            <DropdownItem href="#">
                <ShieldCheckIcon />
                <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownItem href="#">
                <LightBulbIcon />
                <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem> */}
            <DropdownItem href="#" onClick={() => navigate('/subscribe')}>
                <LightBulbIcon />
                <DropdownLabel>Manage subscription</DropdownLabel>
            </DropdownItem>
            <DropdownItem href="mailto:miraiaiapp@gmail.com">
                <QuestionMarkCircleIcon />
                <DropdownLabel>Email Support</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem href="#" onClick={handleLogout}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
        </DropdownMenu>
    )
}

const SideNavbar: React.FC = () => {
    const documentsMetaInfo = useAppSelector((state) => state.documents.documentsMetaInfo);
    const activeDocumentId = useAppSelector((state) => state.documents.activeDocumentId);
    const dispatch = useAppDispatch();

    const { user, token } = useAuth();
    const [hoveredDocumentId, setHoveredDocumentId] = useState<string | null>(null);

    const navigate = useNavigate();

    const fetchDocumentsMetaInfo = async () => {
        try {
            if (!user) return;

            const response = await fetchWithAuth(
                `${process.env.REACT_APP_PUBLIC_HOST}/api/documents/?userId=${user._id}`,
                token
            );

            if (response && Array.isArray(response.documents)) {
                dispatch(setDocumentsMetaInfo(response.documents));
            } else {
                console.error('SideNavbar: Unexpected response format:', response);
            }
        } catch (error) {
            console.error('SideNavbar: Error fetching documents:', error);
        }
    };

    const handleDocumentSelect = (documentId: string) => {
        console.log("Document selected:", documentId);
        if (window.location.pathname !== `/editor/${documentId}`) {
            dispatch(setActiveDocumentId(documentId));
            navigate(`/editor/${documentId}`);
        }
    };

    const handleNewDocument = async () => {
        if (!user) return;

        try {
            const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
            const newDocument = {
                title: "Untitled Document",
                text: "",
                prompt: "",
                maxWordCount: 0,
                userId: user._id
            };
            const data = await fetchWithAuth(url, token, {
                method: 'POST',
                body: JSON.stringify(newDocument)
            });

            console.log("Document created successfully:", data.id);
            await fetchDocumentsMetaInfo();
            navigate(`/editor/${data.id}`);

        } catch (error) {
            console.error('Error creating document:', error);
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        if (!user) return;

        try {
            const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
            await fetchWithAuth(url, token, {
                method: 'DELETE',
                body: JSON.stringify({ documentId, userId: user._id })
            });

            await fetchDocumentsMetaInfo();

            // Manually remove the document from the documents array
            console.log("Removing document id: ", documentId);
            dispatch(setDocumentsMetaInfo(documentsMetaInfo.filter(doc => doc._id !== documentId)));
            const localDocumentsMetaInfo = documentsMetaInfo.filter(doc => doc._id !== documentId);
            console.log("Documents after deletion: ", localDocumentsMetaInfo);

            // If deleted document is the current document (check url)
            if (window.location.pathname === `/editor/${documentId}`) {
                if (localDocumentsMetaInfo.length > 0) {
                    console.log("Current document deleted, navigating to first document");
                    console.log("The first document is: ", localDocumentsMetaInfo[0]);
                    dispatch(setActiveDocumentId(localDocumentsMetaInfo[0]._id));
                    navigate(`/editor/${localDocumentsMetaInfo[0]._id}`);
                } else {
                    navigate(`/editor/`);
                }
            }
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };  

    return (
        <Sidebar>
            <SidebarHeader>
                <img
                    className="px-16"
                    src="/logo.png"
                    alt="Mirai logo featuring a minimalist design with a smile icon, representing the brand's friendly and supportive approach to essay writing."
                />
            </SidebarHeader>

            <SidebarBody>
                {/* New Document Button */}
                <SidebarSection>
                    <Button
                        className="mx-4"
                        onClick={handleNewDocument}
                        color="dark"
                    >
                        <div className="flex flex-row items-center space-x-2">
                            <CiCirclePlus className="text-xl" />
                            <div className="">
                                <h2 className="text-md font-bold">New document</h2>
                            </div>
                        </div>
                    </Button>
                </SidebarSection>

                {/* Document List */}
                <SidebarSection className="max-lg:hidden">
                    <SidebarHeading>Documents</SidebarHeading>
                    {documentsMetaInfo.map((doc) => (
                        <SidebarItem
                            key={doc._id}
                            className={`w-full items-center rounded-md ${activeDocumentId === doc._id && !(hoveredDocumentId === doc._id)? 'bg-zinc-950/5' : ''}`}
                            onMouseEnter={() => setHoveredDocumentId(doc._id)}
                            onMouseLeave={() => setHoveredDocumentId(null)}
                            onClick={() => handleDocumentSelect(doc._id)}
                        >
                            <div
                                className="flex-1 text-left overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                                {doc.title || `Untitled Document`}
                            </div>
                            {hoveredDocumentId === doc._id && (
                                <FaRegTrashAlt
                                    className="text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteDocument(doc._id);
                                    }}
                                />
                            )}
                        </SidebarItem>
                    ))}
                </SidebarSection>

                <SidebarSpacer />
            </SidebarBody>

            {/* User Profile */}
            <SidebarFooter className="max-lg:hidden">
                {!user?.subscribed && (
                    <Button className="mx-4" onClick={() => navigate('/subscribe')} outline>
                        <FaLightbulb className="text-xl" />
                        <span>Subscribe!</span>
                    </Button>
                )}
                <CreditBar />
                <Dropdown>
                    <DropdownButton as={SidebarItem}>
                        <span className="flex min-w-0 items-center gap-3">
                            <Avatar src={user?.profilePicture ? user.profilePicture : "/profile.png"} className="size-10" square alt="User's profile picture" />
                            <span className="min-w-0">
                                <span className="block truncate text-sm/5 font-medium text-zinc-950">{user?.name ? user.name : "Anonymous User"}</span>
                                <span className="block truncate text-xs/5 font-normal text-zinc-500">
                                    {user?.email ? user.email : "User"}
                                </span>
                            </span>
                        </span>
                        <ChevronUpIcon />
                    </DropdownButton>
                    <AccountDropdownMenu anchor="top start" />
                </Dropdown>
            </SidebarFooter>
        </Sidebar>
    );
};

export default SideNavbar;
