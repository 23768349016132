import { Button } from '../components/Button'
import { Divider } from '../components/Divider'
import { Heading } from '../components/Heading'
import type { Metadata } from 'next'
import { useState } from 'react'
import { fetchWithAuth } from '../utils/fetchWithAuth'
import { useEffect } from 'react'
import { User } from '../utils/types'
import { useAuth } from '../contexts/AuthContext';

export const metadata: Metadata = {
  title: 'Subscription',
}

const handleSubscription = async (event: React.FormEvent, userId: string, token: string) => {
  event.preventDefault();

  const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/checkout`, token, {
    method: 'POST',
    body: JSON.stringify({ userId, lookup_key: 'monthly' }),
  });

  if (response.url) {
    window.location.href = response.url;
  }
};

const handlePortalRequest = async (event: React.FormEvent, userId: string, token: string) => {
  event.preventDefault();

  const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/portal`, token, {
    method: 'POST',
    body: JSON.stringify({ userId }),
  });

  if (response.url) {
    window.location.href = response.url;
  }
};

const ProductDisplay = ({ userId, token }: { userId: string, token: string }) => (
  <div>
    <Heading className="text-center">Upgrade to Mirai+</Heading>
    <Divider className="my-10 mt-6" />
    <section className="flex justify-center items-center gap-x-24">

      {/* Mirai+ Monthly Option */}
      <div className="flex flex-col bg-white rounded-md justify-between p-4 border border-gray-200">
        <div className="flex">
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-semibold">Mirai+ Monthly</h3>
            <h4 className="text-sm mt-2">Benefits:</h4>
            <ul className="list-disc ml-5 text-sm">
              <li className="text-gray-600 mt-2">Get 10,000 credits per month for all your editing needs.</li>
            </ul>
          </div>
        </div>
        <form onSubmit={(e) => handleSubscription(e, userId, token)}>
          <input type="hidden" name="lookup_key" value="monthly" />
          <Button type="submit" className="w-full cursor-pointer mt-2" color="dark">
            {"$19.99 / month"}
          </Button>
        </form>
      </div>
    </section>
  </div>
);

const SuccessDisplay = ({ sessionId, token }: { sessionId: string, token: string }) => {
  return (
    <div>
      <Heading className="text-center">Upgrade to Mirai+</Heading>
      <Divider className="my-10 mt-6" />
      <section className="flex justify-center items-center gap-x-24">
        <div className="flex flex-col bg-white rounded-md justify-between p-4 border border-gray-200">
          <h3>Subscription to Mirai+ successful! Thank you for your support!</h3>
        </div>
        {/* <form onSubmit={(e) => handlePortalRequest(e, sessionId, token)}>
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form> */}
      </section>
    </div>
  );
};

const Message = ({ message }: { message: string }) => (
  <div>
    <Heading className="text-center">Upgrade to Mirai+</Heading>
    <Divider className="my-10 mt-6" />
    <section className="flex justify-center items-center gap-x-24">
      <div className="flex flex-col bg-white rounded-md justify-between p-4 border border-gray-200">
        <h3>{message}</h3>
      </div>
    </section>
  </div>
);

const ManageSubscription = ({ userId, token }: { userId: string, token: string }) => {
  return (
    <div>
      <Heading className="text-center">Manage Your Subscription</Heading>
      <Divider className="my-10 mt-6" />
      <section className="flex justify-center items-center gap-x-24">
        <form onSubmit={(e) => handlePortalRequest(e, userId, token)}>
          <button id="checkout-and-portal-button" type="submit">
            Open Billing Portal
          </button>
        </form>
      </section>
    </div>
  );
};

async function assignCustomerId(sessionId: string, user: User, authenticate: (token: string) => void, token: string) {
  await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/subscription/customerId`, token, {
    method: 'POST',
    body: JSON.stringify({ sessionId, userId: user?._id || ''}),
  });

  // Refresh user
  if (user && !user.subscribed) {
    authenticate(token);  
  }
}

export default function Subscription() {

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const { user, authenticate, token } = useAuth();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      assignCustomerId(query.get('session_id') || '', user, authenticate, token || '');
      setSuccess(true);
      setSessionId(query.get('session_id') || '');
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '' && !user?.subscribed) {
    return <ProductDisplay userId={user?._id || ''} token={token || ''} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} token={token || ''} />;
  } else if (message !== '') {
    return <Message message={message} />;
  } else {
    return <ManageSubscription userId={user?._id || ''} token={token || ''} />;
  }

}
