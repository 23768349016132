// src/store/documentsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document, DocumentMetaInfo } from '../utils/types';

interface DocumentsState {
    documentsMetaInfo: DocumentMetaInfo[];
    activeDocumentId: string | null;
    currentDocument: Document | null;
    currentPrompt: string | null;
    currentMaxWordCount: string | null;
    editorText: string | null;
}

const initialState: DocumentsState = {
    documentsMetaInfo: [],
    activeDocumentId: null,
    currentDocument: null,
    currentPrompt: null,
    currentMaxWordCount: null,
    editorText: null,
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setDocumentsMetaInfo(state, action: PayloadAction<DocumentMetaInfo[]>) {
            state.documentsMetaInfo = action.payload;
        },
        setActiveDocumentId(state, action: PayloadAction<string | null>) {
            state.activeDocumentId = action.payload;
        },
        setCurrentDocument(state, action: PayloadAction<Document | null>) {
            state.currentDocument = action.payload;
        },
        setCurrentPrompt(state, action: PayloadAction<string | null>) {
            state.currentPrompt = action.payload;
        },
        setCurrentMaxWordCount(state, action: PayloadAction<string | null>) {
            state.currentMaxWordCount = action.payload;
        },
        setEditorText(state, action: PayloadAction<string | null>) {
            state.editorText = action.payload;
        },
    },
});

export const {
    setDocumentsMetaInfo,
    setActiveDocumentId,
    setCurrentDocument,
    setCurrentPrompt,
    setCurrentMaxWordCount,
    setEditorText,
} = documentsSlice.actions;

export default documentsSlice.reducer;