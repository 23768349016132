import Editor from '../components/Editor';
import { fetchWithAuth } from "../utils/fetchWithAuth";
import DocumentSettings from '../components/DocumentSettings';
import { useState, useEffect, useMemo, useRef } from 'react';
import { FaSpinner, FaRegEdit, FaClipboardList } from 'react-icons/fa';
import { Button } from '../components/Button';
import { Divider } from '../components/Divider';
import { useLocation } from 'react-router-dom';
import EditableTitle from '../components/EditableTitle';
import { useAuth } from '../contexts/AuthContext';
import isEqual from 'lodash/isEqual';
import { Modal, Box, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { HiOutlineSparkles } from "react-icons/hi2";

import { useAppSelector, useAppDispatch } from '../hooks';
import { setActiveDocumentId, setCurrentDocument } from '../store/documentsSlice';
import { setCurrentCredits } from '../store/creditsSlice';

function DocumentPage() {
    const { user, token } = useAuth();
    const dispatch = useAppDispatch();
    const activeDocumentId = useAppSelector((state) => state.documents.activeDocumentId);
    const currentDocument = useAppSelector((state) => state.documents.currentDocument);
    const currentCredits = useAppSelector((state) => state.credits.currentCredits);
    const location = useLocation();

    const documentId = useMemo(() => {
        const id = location.pathname.split('/').pop();
        return id === "" ? null : id;
    }, [location.pathname]);

    console.log("The current document ID is: ", documentId);

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const editorContentRef = useRef<string>('');
    const editorContent = useAppSelector((state) => state.documents.editorText);

    useEffect(() => {
        editorContentRef.current = editorContent ?? '';
    }, [editorContent]);

    useEffect(() => {
        if (!user) return;
        if (documentId === "editor" || !documentId) return;
        console.log("useEffect in DocumentPage running");

        if (documentId !== activeDocumentId) {
            console.log("Document ID in useEffect: ", documentId);
            console.log("Active Document ID in useEffect: ", activeDocumentId);
            console.log("Document ID changed to: ", documentId);
            dispatch(setActiveDocumentId(documentId));
        }

        const fetchDocument = async () => {
            setIsLoading(true);
            if (documentId) {
                try {
                    const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents?id=${documentId}&userId=${user._id}`, token);
                    if (!isEqual(response.document, currentDocument)) {
                        dispatch(setCurrentDocument(response.document));
                    }
                } catch (error) {
                    handleFetchError(error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchDocument();
    }, [documentId, user, token, activeDocumentId, currentDocument, dispatch]);

    const updateDocument = async (id: string, text: string) => {
        const htmlText = text.replace(/\n/g, '<br>');
        const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
        const response = await fetchWithAuth(url, token, {
            method: 'PATCH',
            body: JSON.stringify({ id, text: htmlText })
        });
        console.log("The document to be set is: ", response.document);

        dispatch(setCurrentDocument(response.document));
    };

    const handleRevise = async () => {
        if (!currentDocument) return;

        setIsEditing(true);
        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/reviseText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            if (data && data.text) {
                console.log(data);
                updateDocument(currentDocument._id, data.text);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleAnalyze = async () => {
        if (!currentDocument) return;

        setIsEditing(true);

        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/analyzeText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            console.log("In handleAnalyze, received data: ", data);
            if (data && data.text) {
                setModalContent(data.text);
                setModalOpen(true);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            // Extract the error message from the response
            // const err = error as { response?: { error: string } };
            // console.log("In handleCheck, received error: ", error);
            // if (err.response && err.response.error) {
            //     setModalContent(err.response.error);
            // } else {
            //     setModalContent("An unexpected error occurred.");
            // }
            // setModalOpen(true);
            handleFetchError(error);
        } finally {
            setIsEditing(false);
        }
    };

    const handlePolish = async () => {
        if (!currentDocument) return;

        setIsEditing(true);
        if (!user) return;

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/polishText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: editorContentRef.current, documentId: currentDocument._id, userId: user._id })
            });
            if (data && data.text) {
                console.log(data);
                updateDocument(currentDocument._id, data.text);
                dispatch(setCurrentCredits(Math.max(0, currentCredits - data.creditUsage)));
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleFetchError = (error: unknown) => {
        const err = error as { response?: { status: number } };
        if (err.response && err.response.status === 401) {
            window.location.href = '/login';
        }
        console.error('Error:', error);
    };

    return (
        <div className="flex w-full h-full overflow-hidden">
            <div className="flex w-full flex-col relative h-full">
                <div className="w-full mt-1">
                    <EditableTitle />
                </div>

                {documentId && (
                    <div className="w-full mt-1">
                        <div className="flex flex-col lg:flex-row items-start lg:items-start lg:space-x-4">
                            <div className="flex space-x-4">
                                {user && user.currentCredits <= 0 ? (
                                    <Tippy content="You are out of credits. Subscribe to get more!" placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <div>
                                            <Button
                                                color="dark"
                                                disabled={true}
                                                className="w-16 lg:w-24 text-sm"
                                            >
                                                <HiOutlineSparkles className="text-base lg:text-lg"/>
                                                <div className="hidden lg:inline">Polish</div>
                                            </Button>
                                        </div>
                                    </Tippy>
                                ) : (
                                    <Tippy content="Fix grammar, spelling, and errors." placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <Button
                                            color="dark"
                                            onClick={handlePolish}
                                            {...(isEditing && { 'data-disabled': true })}
                                            className="w-16 lg:w-24 text-sm"
                                        >
                                            <HiOutlineSparkles className="text-base lg:text-lg"/>
                                            <div className="hidden lg:inline">Polish</div>
                                        </Button>
                                    </Tippy>
                                )}

                                {user && user.currentCredits <= 0 ? (
                                    <Tippy content="You are out of credits. Subscribe to get more!" placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <div>
                                            <Button
                                                color="dark"
                                                disabled={true}
                                                className="w-16 lg:w-24 text-sm"
                                            >
                                                <FaRegEdit className="text-base lg:text-lg"/>
                                                <div className="hidden lg:inline">Revise</div>
                                            </Button>
                                        </div>
                                    </Tippy>
                                ) : (
                                    <Tippy content="Reorganize and improve the entire essay." placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <Button
                                            color="dark"
                                            onClick={handleRevise}
                                            {...(isEditing && { 'data-disabled': true })}
                                            className="w-16 lg:w-24 text-sm"
                                        >
                                            <FaRegEdit className="text-base lg:text-lg"/>
                                            <div className="hidden lg:inline">Revise</div>
                                        </Button>
                                    </Tippy>
                                )}

                                {user && user.currentCredits <= 0 ? (
                                    <Tippy content="You are out of credits. Subscribe to get more!" placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <div>
                                            <Button
                                                color="dark"
                                                className="w-16 lg:w-24 text-sm"
                                                disabled={true}
                                            >
                                                <FaClipboardList className="text-base lg:text-lg" /> 
                                                <div className="hidden lg:inline">Analyze</div>
                                            </Button>
                                        </div>
                                    </Tippy>
                                ) : (
                                    <Tippy content="Get feedback on strengths and weaknesses." placement="bottom" delay={[200, 100]} className="bg-white rounded-md p-2 border border-gray-300">
                                        <Button
                                            color="dark"
                                            className="w-16 lg:w-24 text-sm"
                                            onClick={handleAnalyze}
                                            {...(isEditing && { 'data-disabled': true })}
                                        >
                                            <FaClipboardList className="text-base lg:text-lg" /> 
                                            <div className="hidden lg:inline">Analyze</div>
                                        </Button>
                                    </Tippy>
                                )}
                            </div>

                            <div className="flex-grow mt-4 lg:mt-0 lg:ml-4 w-full">
                                <DocumentSettings />
                            </div>
                        </div>
                    </div>
                )}

                {documentId &&
                    <Divider className="my-5" soft />
                }

                <div className="flex flex-1">
                    {!isLoading && documentId && currentDocument ? (
                        <Editor />
                    ) : (
                        <></>
                    )}
                    {isEditing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <FaSpinner className="text-black text-6xl animate-spin" />
                        </div>
                    )}
                </div>

                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4
                        }}
                    >
                        <Typography id="modal-title" variant="h6" component="h2">
                            Comments and Suggestions
                        </Typography>
                        <div id="modal-description" style={{ marginTop: '16px' }} dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export { DocumentPage };
