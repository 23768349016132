import { configureStore } from '@reduxjs/toolkit';
import documentsReducer from './store/documentsSlice';
import creditsReducer from './store/creditsSlice';
const store = configureStore({
    reducer: {
        documents: documentsReducer,
        credits: creditsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
export default store;