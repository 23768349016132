import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreditsState {
    currentCredits: number;
    maxCredits: number;
}

const initialState: CreditsState = {
    currentCredits: 0,
    maxCredits: 100,
};

const creditsSlice = createSlice({
    name: 'credits',
    initialState,
    reducers: {
        setCurrentCredits(state, action: PayloadAction<number>) {
            state.currentCredits = action.payload;
        },
        setMaxCredits(state, action: PayloadAction<number>) {
            state.maxCredits = action.payload;
        },
    },
});

export const {
    setCurrentCredits,
    setMaxCredits,
} = creditsSlice.actions;

export default creditsSlice.reducer;