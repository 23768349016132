import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import { TextStyle, TextStyleOptions } from '@tiptap/extension-text-style'
import { BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import { Extension } from '@tiptap/core';
import { FaRedo } from "react-icons/fa";
import { FaUndo } from "react-icons/fa";
import { useEffect } from 'react'
import { Editor as TiptapEditor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { fetchWithAuth } from "../utils/fetchWithAuth";
import { useAuth } from '../contexts/AuthContext';
import { useDebounce } from 'use-debounce';
import { useState } from 'react';
import Tippy from '@tippyjs/react';

import { useAppSelector, useAppDispatch } from '../hooks';
import { setEditorText } from '../store/documentsSlice';

const LiteralTab = Extension.create({
    name: 'literalTab',

    addKeyboardShortcuts() {
        return {
            Tab: () => {
                console.log('tab');
                // return this.editor.commands.insertContent('\t')
                return true;
            }
        }
    }
})

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] } as Partial<TextStyleOptions>),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
    }),
    Underline,
    LiteralTab,
]

const editorProps = {
    attributes: {
        class: 'min-h-full prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none text-black px-2 sm:px-4 md:px-8 lg:px-16 xl:px-24',
    },
}

function resetEditorContent(editor: TiptapEditor, newContent: string) {
    editor.commands.setContent(newContent);

    // Clear the history
    const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema
    });
    editor.view.updateState(newEditorState);
}

export default function Editor() {

    const { token } = useAuth();
    const activeDocumentId = useAppSelector((state) => state.documents.activeDocumentId);
    const currentDocument = useAppSelector((state) => state.documents.currentDocument);
    const [isLoadingInitialContent, setIsLoadingInitialContent] = useState(true);

    const editor = useEditor({
        extensions: extensions,
        content: '',
        editorProps: editorProps,
        parseOptions: {
            preserveWhitespace: 'full'
        }
    });
    const [debouncedEditor] = useDebounce(editor?.state.doc.content, 500);

    const dispatch = useAppDispatch();

    const handleDocumentUpdate = async (text: string) => {
        if (activeDocumentId && token && currentDocument) {
            const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
            await fetchWithAuth(url, token, {
                method: 'PATCH',
                body: JSON.stringify({ id: activeDocumentId, text })
            });
            dispatch(setEditorText(text));
            
            // dispatch(setCurrentDocument({
            //     _id: activeDocumentId,
            //     text,
            //     title: currentDocument.title,
            //     prompt: currentDocument.prompt,
            //     maxWordCount: currentDocument.maxWordCount,
            //     dateCreated: currentDocument.dateCreated
            // }));
        }
    };

    useEffect(() => {
        // Make sure to not save until initial content is loaded
        if (debouncedEditor && editor && currentDocument?._id && !isLoadingInitialContent) {
            console.log("Saving editor content: ", editor.getHTML());
            handleDocumentUpdate(editor.getHTML());
        }
    }, [debouncedEditor, currentDocument?._id, editor, isLoadingInitialContent]);


    // UseEffect for setting document content on init (use reset to prevent being able to undo)
    useEffect(() => {
        setIsLoadingInitialContent(true);
        if (editor && currentDocument) {
            const currentContent = editor.getHTML();
            const newContent = currentDocument.text;
            if (currentContent !== newContent) {
                resetEditorContent(editor, newContent);
            }
        }
        setIsLoadingInitialContent(false);
    }, [currentDocument, editor]);

    // useEffect(() => {
    //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    //         if (editor) {
    //             handleDocumentUpdate(editor.getHTML());
    //         }
    //     };
    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, [editor]);

    return (
        <div className='flex flex-col h-[calc(100vh-220px)] w-full'>
            <div>
                {editor && <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
                    <div className="bubble-menu flex flex-row p-1 rounded-lg bg-white border-1 border-gray-50">
                        <button
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <strong>B</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <em>I</em>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleUnderline().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <u>U</u>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().undo().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <FaUndo />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().redo().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <FaRedo />
                        </button>
                    </div>
                </BubbleMenu>}
            </div>
            <div className="flex-1 overflow-y-auto">
                <EditorContent 
                    editor={editor} 
                    className="editor-content h-full" 
                    style={{ whiteSpace: 'pre-wrap', overflowY: 'auto' }}
                />
                {/* <Tippy content="This is a tooltip">
                    <span>Hover over thist</span>
                </Tippy> */}
            </div>
        </div>
    )
}
