import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID? process.env.REACT_APP_GOOGLE_CLIENT_ID : '';
root.render(
  <BrowserRouter>
    <AuthProvider>
      <GoogleOAuthProvider clientId={google_client_id}>
        <Provider store={store}>
          <React.StrictMode>
            <App />
            </React.StrictMode>
        </Provider>
      </GoogleOAuthProvider>
    </AuthProvider>
  </BrowserRouter>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
