import './App.css';
import { useEffect, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Navigate } from 'react-router-dom';
import Subscription from './pages/Subscription';
import { fetchWithAuth } from './utils/fetchWithAuth';
import { SidebarLayout } from './components/SidebarLayout';
import SideNavbar from './pages/SideNavbar';
import { DocumentPage } from './pages/DocumentPage';
import Settings from './pages/Settings'; // Updated import
import { Outlet } from 'react-router-dom';
import Landing from './pages/Landing';

import { useAppSelector, useAppDispatch } from './hooks';
import { setDocumentsMetaInfo } from './store/documentsSlice';

function App() {
  const { user, token } = useAuth();
  const documentsMetaInfo = useAppSelector((state) => state.documents.documentsMetaInfo);
  const dispatch = useAppDispatch();

  const navbar = (
    <div className="flex items-center space-x-4">
    </div>
  );

  const ProtectedLayout = memo(function ProtectedLayout() {
    const { token, isAuthenticating } = useAuth();
    if (isAuthenticating) return null;
    if (!token) return <Navigate to="/login" />;
  
    return (
      <SidebarLayout navbar={navbar} sidebar={<SideNavbar />}>
        <Outlet />
      </SidebarLayout>
    );
  });

  const fetchDocumentsMetaInfo = async () => {
    try {
      if (!user) return;
      console.log("fetchDocumentsMetaInfo called");

      const response = await fetchWithAuth(
        `${process.env.REACT_APP_PUBLIC_HOST}/api/documents/?userId=${user._id}`,
        token
      );

      if (response && Array.isArray(response.documents)) {
        dispatch(setDocumentsMetaInfo(response.documents));
        console.log('Documents meta info updated in Redux state');
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    if (user && documentsMetaInfo.length === 0) {
      fetchDocumentsMetaInfo();
    }
  }, [user,documentsMetaInfo.length]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={user && token ? <Navigate to="/editor" /> : <Login />} />
      <Route path="/register" element={user && token ? <Navigate to="/editor" /> : <Register />} />

      <Route element={<ProtectedLayout />}>
        <Route path="editor" element={<DocumentPage />} />
        <Route path="editor/:documentId" element={<DocumentPage />} />
        <Route path="settings" element={<Settings user={user} token={token} />} />
        <Route path="subscribe" element={<Subscription />} />
      </Route>

      <Route path="*" element={user && token ? <Navigate to="/editor" /> : <Navigate to="/" />} />
    </Routes>
  );
}

export default App;
