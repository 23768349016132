import React from 'react';
import { Button } from '../components/Button';
import { Helmet } from 'react-helmet';

const Landing: React.FC = () => {
    return (
        <div className="flex flex-col items-center min-h-screen bg-white text-black">
            {/* SEO */}
            <Helmet>
                <title>Mirai AI - Your Gateway to Admission Success</title>
                <meta 
                    name="description" 
                    content="Mirai provides expert advice and proven personal statement strategies to help students achieve admission success." 
                />
                <meta 
                    name="keywords" 
                    content="AI writing assistant, college admission, personal statement strategies, essay revision, application tools" 
                />
                <link rel="canonical" href="https://www.mirai.zone" />
            </Helmet>

            {/* Header */}
            <div className="border-b shadow-sm w-full fixed top-0 bg-white z-10">
                <div className="flex justify-between py-4 w-2/3 mx-auto">
                    <img src="/logo.png" alt="Company Logo" className="h-10" />
                    <div className="flex space-x-4">
                        <Button href="/login" plain>
                            Login
                        </Button>
                        <Button href="/register" color="dark">
                            Start Writing
                        </Button>
                    </div>
                </div>
            </div>
            {/* Main Content */}
            <div className="mt-18 w-full">
                {/* Slogan and Image */}
                <div className="w-1/2 mx-auto">
                    <main className="flex flex-col items-center text-center mt-32">
                        <h1 className="text-7xl text-zinc-900" style={{ fontFamily: 'Times New Roman, serif' }}>
                            Mirai AI, Your Gateway to Admission Success
                        </h1>
                        <p className="text-gray-500 mt-4 text-2xl text-zinc-600">
                        Expert advice and proven personal statement strategies at your fingertips.
                        </p>
                        <img
                            src="/screenshot.png"
                            alt="Screenshot of Mirai's AI-powered writing assistant interface, showing features like essay analysis and revision tools for college applications"
                            className="mt-16 rounded-lg shadow-2xl shadow-black"
                            style={{
                                maskImage: 'linear-gradient(to bottom, black 15%, transparent 90%)',
                                WebkitMaskImage: 'linear-gradient(to bottom, black 15%, transparent 90%)'
                            }}
                        />
                    </main>
                </div>
                {/* Features */}
                <div className="bg-neutral-50 py-16">
                    <div className="text-center text-5xl text-zinc-800" style={{ fontFamily: 'Times New Roman, serif' }}>Why Mirai?</div>
                    <div className="text-center text-2xl mt-8 text-zinc-600">A range of features to ensure your application is the best it can be</div>
                    <section className="flex justify-between mt-16 w-2/3 mx-auto px-0">
                        <div className="flex flex-col items-center border border-gray-200 p-4 rounded-lg shadow-md flex-1 bg-white mr-8">
                            <h2 className="text-3xl" style={{ fontFamily: 'Times New Roman, serif' }}>Revise Your Essays</h2>
                            <img 
                                src="/revise.svg" 
                                alt="Illustration of a person revising a draft at a desk, representing the Mirai app's essay refinement process for college applications." className="px-8 py-4" 
                            />
                            <p className="text-left text-xl text-zinc-500">Mirai steps in as your personal essay assistant, combining insights from successful applications with your unique profile. Through a multi-stage process, Mirai refines your draft, bringing out its strengths and aligning it with what admissions teams look for.</p>
                        </div>
                        <div className="flex flex-col items-center border border-gray-200 p-4 rounded-lg shadow-md flex-1 bg-white">
                            <h2 className="text-3xl" style={{ fontFamily: 'Times New Roman, serif' }}>Analyze in Depth</h2>
                            <img 
                                src="/analyze.svg" 
                                alt="Illustration of a consultant reviewing documents with a student, symbolizing Mirai's in-depth essay analysis and personalized feedback features." 
                                className="px-8 py-4" 
                            />
                            <p className="text-left text-xl text-zinc-500">Just like a real consultant, Mirai offers guidance with tips and advices from writing professionals and admissions officers. It thoroughly evaluates your essay, highlighting what shines and what needs work, so you can make confident improvements and submit your best possible work.</p>
                        </div>
                        <div className="flex flex-col items-center border border-gray-200 p-4 rounded-lg shadow-md flex-1 bg-white ml-8">
                            <h2 className="text-3xl" style={{ fontFamily: 'Times New Roman, serif' }}>Authenticity Matters</h2>
                            <img 
                                src="/authenticity.svg" 
                                alt="Illustration of a person sitting on a couch with a laptop, representing Mirai's ability to help users write authentic and personal essays in a comfortable, stress-free environment." 
                                className="px-8 py-4" 
                            />
                            <p className="text-left text-xl text-zinc-500">With Mirai, your essay maintains a personal, human touch. It doesn't just add polish; it helps you tell your story in a genuine voice, capturing your personality and crafting an authentic narrative that resonates with admissions teams.</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Landing;